input[type=text],input[type=email],input[type=number], type[radio]{
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    box-sizing: border-box;
    border: 2px solid #16477E;
    border-radius: 4px;
    height: 50%;
}


input[type=submit]{
    background-color: white;
    color:#16477E;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    cursor: pointer;
    width: 100%;
}

form{
    width: 70%;
    margin: auto;
    padding: 2%;
    text-align: left;
    font-family: 'Raleway';
    font-size: 12px;
    font-weight: 200;
    color: white;
    background-color: #16477E;
}

@media only screen and (max-width: 600px) {
    input[type=text],input[type=email],input[type=number], type[radio]{
        font-size: small;
        height: 20%;
    }

    input[type=submit]{
        font-size: small;
        padding: 3px 6px;
    }
    
    label{
        font-size: small;
    }

    form{
        width: 100%;
        padding: 2%;
    }



}